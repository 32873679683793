<template>
  <div class="d-flex | phone-input">
    <div v-if="!disabled" class="mr-6 | phone-input__country">
      <v-select
        v-model="countryCode"
        variant="filled"
        menu-icon="custom:keyboard_arrow_down"
        :items="countriesWithPhoneCode"
        :disabled="disabled"
        :label="label"
        data-cy="country_code_select"
        item-title="name"
      >
        <template #selection>
          <nuxt-img width="30" height="20" :src="`/img/flags/${countryCode}.svg`" />
        </template>

        <template #item="{ item, props }">
          <v-list-item v-bind="props">
            <div class="d-flex align-center">
              <nuxt-img width="30" height="20" :src="`/img/flags/${item.raw.value}.svg`" class="mr-4" />
              {{ item.raw.text }} (+{{ item.raw.code }})
            </div>
          </v-list-item>
        </template>
      </v-select>
    </div>
    <div class="flex-grow-1">
      <v-text-field
        v-model="phoneNumber"
        variant="filled"
        :label="$t('global.mobile_number')"
        :hint="
          $t('global.mobile_editor_help_text', {
            mobile_number: exampleNumber,
          })
        "
        :disabled="disabled"
        data-cy="phone_number_input"
        @blur="formatNumber"
      >
        <template v-if="isValidInput" #append-inner>
          <v-icon size="x-small" class="mx-1 my-2" color="success">custom:circle_dot</v-icon>
        </template>
      </v-text-field>
    </div>
  </div>
</template>

<script lang="ts">
import examples from 'libphonenumber-js/examples.mobile.json'
import type { CountryCode } from 'libphonenumber-js/min'
import {
  findPhoneNumbersInText,
  getCountryCallingCode,
  getExampleNumber,
  parsePhoneNumberFromString,
} from 'libphonenumber-js/mobile'

export default defineComponent({
  name: 'PhoneInput',
  props: {
    modelValue: String,
    label: String,
    disabled: Boolean,
  },
  setup(props, { emit }) {
    const { countryList } = storeToRefs(useLanguageStore())

    const countryCode = ref<CountryCode>('CH')
    const phoneNumber = ref('')
    const parsedPhoneNumber = computed(() => {
      return parsePhoneNumberFromString(phoneNumber.value, countryCode.value)
    })
    // note : we prepopulate user's existing number immediately, not in onMounted method
    // because watchEffect is called immediately and before onMounted, and it needs to have the proper phone number set
    if (props.modelValue) {
      /* correctly load user's existing number */
      const parsed = findPhoneNumbersInText(`+${props.modelValue}`)
      countryCode.value = parsed?.[0]?.number?.country || countryCode.value
      phoneNumber.value = parsed?.[0]?.number?.nationalNumber || phoneNumber.value
    }

    onMounted(() => {
      void nextTick(() => {
        formatNumber()
      })
    })

    const isValidInput = computed(() => {
      return Boolean(parsedPhoneNumber.value?.isValid())
    })

    watchEffect(() => {
      if (props.disabled) return

      const number = parsedPhoneNumber.value?.number
      if (number) {
        emit('update:modelValue', number.substring(1))
      } else {
        emit('update:modelValue', '')
      }
      emit('phone-number-compliant', isValidInput.value)
    })

    const exampleNumber = computed(() => {
      if (!countryCode.value) return ''

      const exampleNumber = getExampleNumber(countryCode.value, examples)
      return exampleNumber?.formatNational() ?? ''
    })

    const formatNumber = () => {
      if (parsedPhoneNumber.value) {
        phoneNumber.value = parsedPhoneNumber.value.formatNational()
        if (parsedPhoneNumber.value.country && parsedPhoneNumber.value.country !== countryCode.value) {
          countryCode.value = parsedPhoneNumber.value.country
        }
      }
    }

    const countriesWithPhoneCode = computed(() => {
      return countryList.value
        .map(country => {
          let countryCallingCode = ''

          try {
            countryCallingCode = getCountryCallingCode(country.value as CountryCode).toString()
          } catch {
            countryCallingCode = ''
          }

          return {
            ...country,
            // note : name should be empty - v-select needs something for name, but we don't want it to be shown on ui
            name: '',
            code: countryCallingCode,
          }
        })
        .filter(country => country.code !== '' || country.divider)
    })

    return {
      countryCode,
      phoneNumber,
      exampleNumber,
      isValidInput,
      countriesWithPhoneCode,
      formatNumber,
    }
  },
})
</script>

<style lang="sass">
.phone-input
  &__country
    max-width: 90px
</style>
